
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import * as types from "@/store/types";
import axios, { AxiosResponse, AxiosError } from "axios";
import { sleep } from "@/utils";
import { ResponseError } from "@/interfaces";
@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Prices extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    title = "Prueba de Traducción";
    titulo = this.title;
    defaultT = [
        "Vamos a realizar una prueba de google translate, para eso hemos creado este VUE",
        "Estamos realizando pruebas de google cloud translate",
        "Se espera que los textos del dashboard estén en multilenguaje",
        "Equipo de Desarrollo: Leander Arruti, Johan Herrera, Mauricio Orjuela",
        "Esperamos que sean útiles están herramientos que presentamos a nuestros clientes",
        "Este cambio ni se le espera, ni se necesita"
    ];
    texto: string[] = Object.assign([], this.defaultT);
    client = axios.create();
    toggle_exclusive = 0;
    // require("dotenv").config();
    // Your credentials
    // Configuration for the client

    async translateText<T>(text, lang: string) {
        return this.client
            .request<T>({
                method: "post",
                url:
                    "https://translation.googleapis.com/language/translate/v2?key=AIzaSyBUF0tLWD1fG0PR-9nQ3j7G1ziZxSR615k",
                data: {
                    q: text,
                    target: lang
                }
            })
            .then((res: AxiosResponse) => {
                return res.data;
            })
            .catch((e: AxiosError) => {
                console.error(e);
                console.log(e.response?.data);

                const errorData = e.response?.data as ResponseError;
                const txt = errorData ? errorData.detail : e.message;

                throw new Error(txt);
            }) as Promise<T>;
    }

    mounted() {
        this.dosomething();
    }

    async dosomething() {
        let trans: any;
        let trans2: any;
        switch (this.toggle_exclusive) {
            case 1:
                trans = await this.translateText(this.texto, "en");
                trans2 = await this.translateText(this.titulo, "en");
                break;
            case 2:
                trans = await this.translateText(this.texto, "fr");
                trans2 = await this.translateText(this.titulo, "fr");
                break;
        }
        if (this.toggle_exclusive != 0) {
            const aux: string[] = [];
            for (const i of trans.data.translations) {
                aux.push(this.convertToPlain(i.translatedText));
            }
            this.titulo = this.convertToPlain(
                trans2.data.translations[0].translatedText
            );
            this.texto = aux;
        } else {
            this.texto = Object.assign([], this.defaultT);
            this.titulo = this.title;
        }
    }

    convertToPlain(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }
}
